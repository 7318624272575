import React from "react"
import ReactDOM from "react-dom/client"
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpApi from "i18next-http-backend"
import { initReactI18next, useTranslation } from "react-i18next"

// Libraries
import { BrowserRouter, Route, Routes, Link } from "react-router-dom"
import { ParallaxProvider } from "react-scroll-parallax"
import { LazyMotion, domMax } from "framer-motion"
import CookieConsent from "react-cookie-consent"

import FooterStyle04 from "./Components/Footers/FooterStyle04"
import LogoSVG from "./Assets/img/logo.svg"

import ScrollToTop from "./Components/Custom/ScrollToTop"
import Heading from "./Components/Custom/Heading"

//pages
import PrivacyPolicy from "./Page/PrivacyPolicy"
import AppInvite from "./Page/AppInvite"
import Landing from "./Page/Landing"
import NotFoundPage from "./Page/404"

// css
import "./Assets/css/icons.css"
import "./Assets/css/global.css"
import "./Assets/css/pages.css"
import "./index.scss"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs: ["en", "it"],
    fallbackLng: "en",
    load: "languageOnly",
    detection: {
      order: ["path", "navigator"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  })
const App = () => {
  const { t } = useTranslation()

  return (
    <LazyMotion features={domMax}>
      <ParallaxProvider>
        <BrowserRouter>
          <ScrollToTop>
            <CookieConsent
              buttonText={t("cookie_accept")}
              cookieName="cookie_accepted"
              style={{ background: "#2B373B", display: "flex" }}
              buttonStyle={{
                color: "#FAFAFA",
                fontSize: "13px",
                backgroundColor: "#3369b1",
                fontFamily: "Montserrat, sans-serif",
              }}
              expires={365}
            >
              {t("cookie_heading")}
              <Link aria-label="link" to="/privacy-policy" className="ml-4 underline">
                {t("cookie_discover")}
              </Link>
            </CookieConsent>
            <Heading />
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/invite" element={<AppInvite />} />
              <Route
                    path="*"
                    element={<NotFoundPage />}
                />
            </Routes>
            {/* Footer Start */}
            <FooterStyle04 className="finance-footer border-t border-[#0000001a]" theme="light" logo={LogoSVG} />
            {/* Footer End */}
          </ScrollToTop>
        </BrowserRouter>
      </ParallaxProvider>
    </LazyMotion>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<App />)
