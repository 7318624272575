import React, { useState } from "react"

// Libraries
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

// Components
import Buttons from "../Components/Button/Buttons"
import Bg from "../Assets/img/Bg.jpg"
import SideButtons from "../Components/SideButtons"
import DownloadFromGoogleIT from "../Assets/img/g8.svg"
import DownloadFromGoogleENG from "../Assets/img/Google-eng.svg"

// Data

// function
import InViewPort from "../Components/InViewPort"

const AppInvite = (props) => {
  const { t } = useTranslation()
  const [appleURL, setAppleURL] = useState(
    "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/" +
      i18next.language.split("-")[0] +
      "?size=250x83&releaseDate=1713225600"
  )
  const [lang, setLang] = useState(i18next.language.split("-")[0])

  const handleLanguageChange = () => {
    setLang(i18next.language.split("-")[0])
    if (i18next.language.split("-")[0] === "it") {
      setAppleURL(
        "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/it-it?size=250x83&releaseDate=1713225600"
      )
    } else {
      setAppleURL(
        "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1713225600"
      )
    }
  }

  i18next.on("languageChanged", handleLanguageChange)

  return (
    <div style={props.style}>
      <SideButtons />
      <Helmet>
        <meta name="apple-itunes-app" content="app-id=6475370551" />
      </Helmet>
      {/* Section Start */}
      <section className="bg-cover bg-no-repeat overflow-visible bg-center" style={{ backgroundImage: `url(${Bg})` }}>
        <Container>
          <Row className="justify-center sm:text-center">
            <Col
              lg={6}
              xl={5}
              md={7}
              sm={8}
              className="h-[100vh] flex flex-col items-start justify-center pb-40 pt-24 lg:pt-40 lg:pb-32 md:pt-24 md:pb-40 sm:h-auto sm:pb-20 md:h-[650px]"
            >
              <h1 className="font-serif text-white font-bold tracking-[-3px] uppercase mb-10">Silver Eye Hub</h1>
              <p className="text-lg leading-[30px] text-[#FAFAFA]  mb-[25px] font-light w-[85%] sm:w-full md:text-xmd sm:mb-[15px] xs:leading-[22px]">
                {t("downloadAppInvite")}
              </p>

              <div className=" flex gap-x-4">
                <div className="flex-1">
                  <a
                    href="https://apps.apple.com/it/app/silver-eye-hub/id6475370551?itsct=apps_box_badge&amp;itscg=30200"
                    style={{ borderadius: 13, height: 60 }}
                    target="_blank"
                  >
                    <img src={appleURL} alt="Download on the App Store" style={{ borderadius: 13, height: 60 }} />
                  </a>
                </div>
                <div className="flex-1">
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.magicvision.silvereye">
                    {lang === "en" ? (
                      <img src={DownloadFromGoogleENG} className="h-[60px] " alt="no-data" width="" height="" />
                    ) : (
                      <img src={DownloadFromGoogleIT} className="h-[60px]" alt="no-data" width="" height="" />
                    )}
                  </a>
                </div>
              </div>
            </Col>
            <Col xl={{ offset: 1 }} lg={6} md={5} className="z-0 text-left self-end md:self-center"></Col>
          </Row>
        </Container>
      </section>
      {/* Section End*/}

      {/* Lazy Load HTML */}
      <InViewPort></InViewPort>
    </div>
  )
}

export default AppInvite
