import React, { memo } from "react"

// Libraries
import { Col, Container, Row } from "react-bootstrap"
import { PropTypes } from "prop-types"
import { m, AnimatePresence } from "framer-motion"
import * as Yup from "yup"
import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useNavigate, Link } from "react-router-dom"

// Components
import MessageBox from "../MessageBox/MessageBox"
import { Input } from "../Form/Form"
import SocialIcons from "../SocialIcon/SocialIcons"
import FooterMenu, { Footer } from "./Footer"
import { resetForm, sendEmail } from "../../Functions/Utilities"
import PrivacyPolicy from "../../Page/PrivacyPolicy"

// Data
import FooterData from "./FooterData"
import { t } from "i18next"

const iconData = [
  {
    color: "#828282",
    link: "https://www.linkedin.com/company/magicvisionai/",
    icon: "fab fa-linkedin",
  },
  {
    color: "#828282",
    link: "https://github.com/MagicVisionAI",
    icon: "fab fa-github",
  },
  {
    color: "#828282",
    link: "https://twitter.com/MagicVisionAI",
    icon: "fab fa-twitter",
  },
  {
    color: "#828282",
    link: "https://www.instagram.com/magicvisionai/",
    icon: "fab fa-instagram",
  },
]

const FooterStyle04 = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Footer
      topSpace={false}
      theme={props.theme}
      className={`footer-style-04${props.className ? ` ${props.className}` : ""}`}
    >
      <div className="py-[6%] lg:py-[8%] md:py-[50px]">
        <Container>
          <Row className="justify-between md:justify-start">
            <Col lg={{ span: 3, order: 0 }} sm={{ span: 6, order: 1 }} className="md:mb-[40px] xs:mb-[25px]">
              <Link aria-label="link" to="/" className="mb-[25px] block">
                <img src={props.logo} alt="logo" width="463" height="72" />
              </Link>
              <p className=" font-serif w-full md:w-[80%] mb-[30px] xs:w-11/12">
                Via XX Settembre 54
                <br /> 22066 Mariano Comense (Co) <br />
                {t("VAT")}: {t("VAT_code")}
              </p>
            </Col>
            <Col lg={3} sm={6} className="xs:mb-[25px]">
              <span className="font-serif'mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]">
                {t("get_in_touch")}
              </span>
              <div className="font-serif">
                <i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>+39 391 4853901
              </div>
              <div className="font-serif">
                <i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>+39 320 9252098
              </div>
              <div className="font-serif">
                <i className=" feather-mail text-sm mr-[10px] text-themecolor"></i>
                <a aria-label="mail" href="mailTo:info@yourdomain.com">
                  info@magicvision.ai
                </a>
              </div>
              <SocialIcons
                theme="social-icon-style-01"
                className="justify-start"
                size="xs"
                iconColor={props.theme === "dark" ? "light" : "dark"}
                data={iconData}
              />
            </Col>
            <Col lg={3} sm={6} className="xs:mb-[25px]">
              <span className="mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]"></span>
              <div className="flex flex-col items-start justify-start">
                <Link to="/privacy-policy">{t("Privacy_policy")}</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Footer>
  )
}

FooterStyle04.defaultProps = {
  data: FooterData,
  logo: "/assets/img/webp/logo-fast-blue-black.webp",
}

FooterStyle04.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
}

export default memo(FooterStyle04)
