import React, { lazy, useState } from "react"

// Libraries
import { Col, Container } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

import { m } from "framer-motion"

// Components
import Buttons from "../Components/Button/Buttons"

import { fadeIn } from "../Functions/GlobalAnimations"
const SideButtons = lazy(() => import("../Components/SideButtons"))

const NotFoundPage = (props) => {
  const { t } = useTranslation()
  const [lan, setLang] = useState(i18next.language.split("-")[0])
  return (
    <div style={props.style}>
      {/* Section Start */}
      <Container className="mt-44 lg:mt-64 mb-44">
        <m.div className="row items-stretch full-screen justify-center" {...fadeIn}>
          <Col xl={6} lg={7} md={8} className="col-12 text-center flex items-center justify-center flex-col">
            <h6 className="font-serif  text-[#3369b1] font-semibold -tracking-[1px] mb-[10px] uppercase">Ooops!</h6>
            <h1 className="font-serif text-[230px] leading-[230px] font-bold tracking-[-5px] text-[#343434] mb-12 lg:text-[170px] lg:leading-[170px] md:text-[130px] md:leading-[130px] md:mb-16 sm:text-[100px] sm:leading-[100px] xs:text-[55px] xs:leading-[55px] xl:text-[200px] xl:leading-[200px]">
              404
            </h1>
            <span className="font-serif font-medium text-darkgray block mb-[20px]">{t("404Message")}</span>
            <Buttons
              to="/"
              className="btn-fill tracking-[1px] font-medium font-serif uppercase btn-fancy text-[#FAFAFA]"
              themeColor="#3369b1"
              color="#ffffff"
              size="lg"
              title={t("back2home ")}
            />
          </Col>
        </m.div>
      </Container>
      {/* Section End */}
    </div>
  )
}

export default NotFoundPage
