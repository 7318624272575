import React, { lazy, useState } from "react"

import { useTranslation } from "react-i18next"

import { t } from "i18next"
import i18next from "i18next"
import { Container } from "react-bootstrap"

function PrivacyPolicy() {
  const { t } = useTranslation()
  const [lan, setLang] = useState(i18next.language.split("-")[0])
  console.log(lan)

  const pStyle = "text-xmd font-serif leading-[30px]   lg:w-[90%] md:w-full "

  return (
    <Container className=" mt-24 lg:mt-20">
      <h1 className="heading-2 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Informativa sulla privacy
      </h1>
      <p className={pStyle}>
        Ultimo aggiornamento: 13 aprile 2024 <br />
        Questa Informativa sulla privacy descrive le nostre politiche e procedure riguardanti la raccolta, l'uso e la
        divulgazione delle tue informazioni quando utilizzi il Servizio e ti informa sui tuoi diritti alla privacy e su
        come la legge ti protegge.
        <br />
        Utilizziamo i tuoi dati personali per fornire e migliorare il Servizio. Utilizzando il Servizio, accetti la
        raccolta e l'uso delle informazioni in conformità con questa Informativa sulla privacy. Questa Informativa sulla
        privacy è stata creata con l'aiuto del{" "}
        <a href="https://www.termsfeed.com/privacy-policy-generator/" target="_blank" className="text-[#3369b1]">
          Generatore di Informativa sulla privacy
        </a>
        .
      </p>
      <h2 className="heading-4 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Interpretazione e definizioni
      </h2>
      <h3 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px]">Interpretazione</h3>
      <p className={pStyle}>
        Le parole con la lettera iniziale maiuscola hanno significati definiti nelle seguenti condizioni. Le seguenti
        definizioni avranno lo stesso significato indipendentemente dal fatto che appaiano al singolare o al plurale.
      </p>
      <h3 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">Definizioni</h3>
      <p className={pStyle}>Per gli scopi di questa Informativa sulla privacy:</p>
      <ul className={pStyle}>
        <li>
          <p>
            <strong>Account</strong> significa un account unico creato per te per accedere al nostro Servizio o a parti
            del nostro Servizio.
          </p>
        </li>
        <li>
          <p>
            <strong>Affiliato</strong> significa un'entità che controlla, è controllata o è sotto il controllo comune
            con una parte, dove "controllo" significa proprietà del 50% o più delle azioni, interessi patrimoniali o
            altri titoli aventi diritto di voto per l'elezione di direttori o altre autorità gestionali.
          </p>
        </li>
        <li>
          <p>
            <strong>Applicazione</strong> si riferisce a Silver Eye, il programma software fornito dalla Società.
          </p>
        </li>
        <li>
          <p>
            <strong>Società</strong> (denominata anche "la Società", "Noi", "Ci" o "Nostro" in questo Accordo) si
            riferisce a Magic Vision SRL, Via XX Settembre 54.
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies</strong> sono piccoli file che vengono inseriti sul tuo computer, dispositivo mobile o
            qualsiasi altro dispositivo da un sito web, contenenti i dettagli della tua cronologia di navigazione su
            quel sito tra i suoi molti utilizzi.
          </p>
        </li>
        <li>
          <p>
            <strong>Paese</strong> si riferisce a: Italia
          </p>
        </li>
        <li>
          <p>
            <strong>Dispositivo</strong> significa qualsiasi dispositivo che può accedere al Servizio come un computer,
            un cellulare o un tablet digitale.
          </p>
        </li>
        <li>
          <p>
            <strong>Dati personali</strong> sono tutte le informazioni che si riferiscono a un individuo identificato o
            identificabile.
          </p>
        </li>
        <li>
          <p>
            <strong>Servizio</strong> si riferisce all'Applicazione o al Sito web o a entrambi.
          </p>
        </li>
        <li>
          <p>
            <strong>Prestatore di servizi</strong> significa qualsiasi persona fisica o giuridica che elabora i dati per
            conto della Società. Si riferisce a società o individui di terze parti impiegati dalla Società per
            facilitare il Servizio, per fornire il Servizio per conto della Società, per eseguire servizi correlati al
            Servizio o per assistere la Società nell'analisi di come viene utilizzato il Servizio.
          </p>
        </li>
        <li>
          <p>
            <strong>Dati di utilizzo</strong> si riferisce ai dati raccolti automaticamente, generati dall'uso del
            Servizio o dall'infrastruttura del Servizio stesso (ad esempio, la durata di una visita alla pagina).
          </p>
        </li>
        <li>
          <p>
            <strong>Sito web</strong> si riferisce a Magic Vision, accessibile da{" "}
            <a href="https://www.magicvision.ai/" rel="external nofollow noopener" target="_blank">
              https://www.magicvision.ai/
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Tu</strong> significa l'individuo che accede o utilizza il Servizio, o l'azienda o altra entità
            giuridica per conto della quale tale individuo accede o utilizza il Servizio, se applicabile.
          </p>
        </li>
      </ul>
      <h2 className="heading-4 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Raccolta e utilizzo dei tuoi dati personali
      </h2>
      <h3 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Tipi di dati raccolti
      </h3>
      <h4 className="heading-6 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">Dati personali</h4>
      <p className={pStyle}>
        Mentre utilizzi il nostro Servizio, potremmo chiederti di fornirci determinate informazioni personalmente
        identificabili che possono essere utilizzate per contattarti o identificarti. Le informazioni personalmente
        identificabili possono includere, ma non sono limitate a:
      </p>
      <ul className={pStyle}>
        <li>
          <strong>Indirizzo email</strong>
        </li>
        <li>
          <strong>Nome e cognome</strong>
        </li>
        <li>
          <strong>Dati di utilizzo</strong>
        </li>
      </ul>
      <h4 className="heading-6 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">Dati di utilizzo</h4>
      <p className={pStyle}>
        I dati di utilizzo vengono raccolti automaticamente durante l'utilizzo del Servizio.
        <br />
        I dati di utilizzo possono includere informazioni come l'indirizzo IP del tuo dispositivo (ad esempio,
        l'indirizzo IP), il tipo di browser, la versione del browser, le pagine del nostro Servizio che visiti, l'ora e
        la data della tua visita, il tempo trascorso su quelle pagine, identificatori univoci del dispositivo e altri
        dati diagnostici.
        <br />
        Quando accedi al Servizio tramite un dispositivo mobile, potremmo raccogliere automaticamente determinate
        informazioni, incluso, ma non limitato a, il tipo di dispositivo mobile che utilizzi, l'ID univoco del tuo
        dispositivo mobile, l'indirizzo IP del tuo dispositivo mobile, il sistema operativo mobile, il tipo di browser
        Internet mobile che utilizzi, identificatori univoci del dispositivo e altri dati diagnostici.
        <br />
        Potremmo raccogliere anche informazioni che il tuo browser invia ogni volta che visiti il nostro Servizio o
        quando accedi al Servizio tramite un dispositivo mobile.
      </p>
      <h4 className="heading-6 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Informazioni raccolte durante l'utilizzo dell'Applicazione
      </h4>
      <p className={pStyle}>
        Mentre utilizzi la nostra Applicazione, per fornire funzionalità della nostra Applicazione, potremmo
        raccogliere, previa tua autorizzazione:
      </p>
      <ul className={pStyle}>
        <li>Immagini e altre informazioni dalla fotocamera e dalla libreria fotografica del tuo dispositivo</li>
      </ul>
      <p className={pStyle}>
        Utilizziamo queste informazioni per fornire funzionalità del nostro Servizio, per migliorare e personalizzare il
        nostro Servizio. Le informazioni possono essere caricate sui server dell'azienda e/o su un server del fornitore
        di servizi o possono essere semplicemente memorizzate sul tuo dispositivo.
        <br />
        Puoi abilitare o disabilitare l'accesso a queste informazioni in qualsiasi momento, tramite le impostazioni del
        tuo dispositivo.
      </p>
      <h4 className="heading-6 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Tecnologie di tracciamento e cookies
      </h4>
      <p className={pStyle}>
        Utilizziamo Cookies e tecnologie di tracciamento simili per tracciare l'attività sul nostro Servizio e
        memorizzare determinate informazioni. Le tecnologie di tracciamento utilizzate sono beacon, tag e script per
        raccogliere e tracciare informazioni e per migliorare e analizzare il nostro Servizio. Le tecnologie che
        utilizziamo possono includere:
      </p>
      <ul className={pStyle}>
        <li>
          <strong>Cookies o Cookie del browser.</strong> Un cookie è un piccolo file inserito sul tuo Dispositivo. Puoi
          istruire il tuo browser a rifiutare tutti i Cookie o a indicare quando viene inviato un Cookie. Tuttavia, se
          non accetti i Cookies, potresti non essere in grado di utilizzare alcune parti del nostro Servizio. A meno che
          tu non abbia modificato le impostazioni del tuo browser in modo che rifiuti i Cookies, il nostro Servizio
          potrebbe utilizzare i Cookies.
        </li>
        <li>
          <strong>Web Beacon.</strong> Alcune sezioni del nostro Servizio e le nostre email possono contenere piccoli
          file elettronici noti come web beacon (anche chiamati clear gif, pixel tag e single-pixel gif) che consentono
          all'azienda, ad esempio, di contare gli utenti che hanno visitato quelle pagine o aperto un'email e per altre
          statistiche correlate al sito web (ad esempio, registrare la popolarità di una determinata sezione e
          verificare l'integrità del sistema e del server).
        </li>
      </ul>
      <p className={pStyle}>
        I Cookies possono essere "Persistenti" o "di sessione". I Cookies persistenti rimangono sul tuo computer
        personale o dispositivo mobile quando vai offline, mentre i Cookie di sessione vengono eliminati non appena
        chiudi il tuo browser web. Puoi saperne di più sui cookies su{" "}
        <a href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies" target="_blank">
          articolo del sito TermsFeed
        </a>
        .
        <br />
        Utilizziamo sia Cookie di sessione che Cookie persistenti per i fini di seguito indicati:
      </p>
      <ul className={pStyle}>
        <li>
          <p>
            <strong>Cookies necessari / essenziali</strong>
          </p>
          <p>Tipo: Cookies di sessione</p>
          <p>Amministrati da: Noi</p>
          <p>
            Scopo: Questi Cookies sono essenziali per fornirti servizi disponibili tramite il Sito web e per consentirti
            di utilizzare alcune delle sue funzionalità. Aiutano ad autenticare gli utenti e a prevenire un uso
            fraudolento degli account degli utenti. Senza questi Cookies, non possono essere forniti i servizi che hai
            richiesto e utilizziamo questi Cookies solo per fornirti quei servizi.
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies della policy / Accettazione dei Cookies</strong>
          </p>
          <p>Tipo: Cookies persistenti</p>
          <p>Amministrati da: Noi</p>
          <p>Scopo: Questi Cookies identificano se gli utenti hanno accettato l'uso dei cookies sul Sito web.</p>
        </li>
        <li>
          <p>
            <strong>Cookies di funzionalità</strong>
          </p>
          <p>Tipo: Cookies persistenti</p>
          <p>Amministrati da: Noi</p>
          <p>
            Scopo: Questi Cookies ci consentono di ricordare le scelte che fai quando utilizzi il Sito web, come ad
            esempio ricordare i tuoi dettagli di accesso o le tue preferenze linguistiche. Lo scopo di questi Cookies è
            quello di fornirti un'esperienza più personale e di evitare che tu debba reinserire le tue preferenze ogni
            volta che utilizzi il Sito web.
          </p>
        </li>
      </ul>
      <p>
        Per ulteriori informazioni sui cookies che utilizziamo e sulle tue scelte riguardanti i cookies, consulta la
        nostra Politica sui cookies o la sezione Cookies della nostra Informativa sulla privacy.
      </p>
      <h3 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Utilizzo dei tuoi dati personali
      </h3>
      <p className={pStyle}>La Società può utilizzare i Dati Personali per i seguenti scopi:</p>
      <ul className={pStyle}>
        <li>
          <p>
            <strong>Per fornire e mantenere il nostro Servizio</strong>, incluso per monitorare l'uso del nostro
            Servizio.
          </p>
        </li>
        <li>
          <p>
            <strong>Per gestire il tuo Account:</strong> per gestire la tua registrazione come utente del Servizio. I
            Dati Personali che fornisci possono darti accesso a diverse funzionalità del Servizio che sono disponibili
            per te come utente registrato.
          </p>
        </li>
        <li>
          <p>
            <strong>Per l'esecuzione di un contratto:</strong> lo sviluppo, la conformità e l'esecuzione del contratto
            di acquisto dei prodotti, articoli o servizi che hai acquistato o di qualsiasi altro contratto con noi
            attraverso il Servizio.
          </p>
        </li>
        <li>
          <p>
            <strong>Per contattarti:</strong> Per contattarti tramite email, telefonate, SMS o altre forme equivalenti
            di comunicazione elettronica, come le notifiche push di un'applicazione mobile riguardanti aggiornamenti o
            comunicazioni informative relative alle funzionalità, prodotti o servizi contrattati, inclusi gli
            aggiornamenti sulla sicurezza, quando necessario o ragionevole per la loro implementazione.
          </p>
        </li>
        <li>
          <p>
            <strong>Per fornirti</strong> notizie, offerte speciali e informazioni generali su altri beni, servizi ed
            eventi che offriamo e che sono simili a quelli che hai già acquistato o richiesto informazioni, a meno che
            tu non abbia scelto di non ricevere tali informazioni.
          </p>
        </li>
        <li>
          <p>
            <strong>Per gestire le tue richieste:</strong> Per gestire e gestire le tue richieste verso di noi.
          </p>
        </li>
        <li>
          <p>
            <strong>Per trasferimenti aziendali:</strong> Possiamo utilizzare le tue informazioni per valutare o
            effettuare una fusione, una cessione, una ristrutturazione, una riorganizzazione, una liquidazione o
            un'altra vendita o trasferimento di tutti o parte dei nostri asset, sia come azienda in funzione che come
            parte di un fallimento, liquidazione o procedimento simile, nel quale i Dati Personali detenuti da noi sui
            nostri utenti del Servizio sono tra gli asset trasferiti.
          </p>
        </li>
        <li>
          <p>
            <strong>Per altri scopi</strong>: Possiamo utilizzare le tue informazioni per altri scopi, come l'analisi
            dei dati, l'identificazione delle tendenze di utilizzo, la determinazione dell'efficacia delle nostre
            campagne promozionali e per valutare e migliorare il nostro Servizio, i nostri prodotti, i nostri servizi,
            il nostro marketing e la tua esperienza.
          </p>
        </li>
      </ul>
      <p className={pStyle}>Possiamo condividere le tue informazioni personali nelle seguenti situazioni:</p>
      <ul className={pStyle}>
        <li>
          <strong>Con fornitori di servizi:</strong> Possiamo condividere le tue informazioni personali con fornitori di
          servizi per monitorare e analizzare l'uso del nostro Servizio, per contattarti.
        </li>
        <li>
          <strong>Per trasferimenti aziendali:</strong> Possiamo condividere o trasferire le tue informazioni personali
          in relazione a, o durante le negoziazioni di, qualsiasi fusione, vendita di beni della Società, finanziamenti
          o acquisizione di tutta o parte della nostra attività da parte di un'altra società.
        </li>
        <li>
          <strong>Con affiliati:</strong> Possiamo condividere le tue informazioni con le nostre affiliate, nel qual
          caso richiederemo a tali affiliate di rispettare questa Informativa sulla privacy. Le affiliate includono la
          nostra società madre e qualsiasi altra filiale, partner di joint venture o altre società che controlliamo o
          che sono sotto il nostro controllo comune.
        </li>
        <li>
          <strong>Con partner commerciali:</strong> Possiamo condividere le tue informazioni con i nostri partner
          commerciali per offrirti determinati prodotti, servizi o promozioni.
        </li>
        <li>
          <strong>Con altri utenti:</strong> quando condividi informazioni personali o interagisci in aree pubbliche con
          altri utenti, tali informazioni possono essere visualizzate da tutti gli utenti e possono essere distribuite
          pubblicamente all'esterno.
        </li>
        <li>
          <strong>Con il tuo consenso</strong>: Possiamo divulgare le tue informazioni personali per qualsiasi altro
          scopo con il tuo consenso.
        </li>
      </ul>
      <h3 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Conservazione dei tuoi dati personali
      </h3>
      <p className={pStyle}>
        La Società conserverà i tuoi Dati Personali solo per il tempo necessario per gli scopi stabiliti in questa
        Informativa sulla privacy. Conserveremo e utilizzeremo i tuoi Dati Personali nella misura necessaria per
        adempiere ai nostri obblighi legali (ad esempio, se siamo tenuti a conservare i tuoi dati per rispettare le
        leggi applicabili), risolvere dispute e far rispettare i nostri accordi legali e le nostre politiche.
        <br />
        La Società conserverà anche i Dati di Utilizzo per scopi di analisi interna. I Dati di Utilizzo sono
        generalmente conservati per un periodo di tempo più breve, tranne quando questi dati vengono utilizzati per
        rafforzare la sicurezza o migliorare la funzionalità del nostro Servizio, o siamo legalmente obbligati a
        conservare questi dati per periodi di tempo più lunghi.
      </p>
      <h3 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Trasferimento dei tuoi dati personali
      </h3>
      <p className={pStyle}>
        Le tue informazioni, compresi i Dati Personali, sono elaborate presso gli uffici operativi della Società e in
        qualsiasi altro luogo in cui si trovano le parti coinvolte nel trattamento. Ciò significa che queste
        informazioni possono essere trasferite a - e mantenute su - computer situati al di fuori del tuo stato,
        provincia, paese o altra giurisdizione governativa dove le leggi sulla protezione dei dati possono essere
        diverse da quelle della tua giurisdizione.
        <br />
        Il tuo consenso a questa Informativa sulla privacy seguito dalla tua presentazione di tali informazioni
        rappresenta il tuo accordo a tale trasferimento.
        <br />
        La Società adotterà tutte le misure ragionevolmente necessarie per garantire che i tuoi dati siano trattati in
        modo sicuro e in conformità con questa Informativa sulla privacy e nessun trasferimento dei tuoi Dati Personali
        avverrà a un'organizzazione o a un paese a meno che non siano presenti controlli adeguati, compresa la sicurezza
        dei tuoi dati e di altre informazioni personali.
      </p>
      <h3 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Cancellazione dei tuoi dati personali
      </h3>
      <p className={pStyle}>
        Hai il diritto di cancellare o richiedere che ti assistiamo nella cancellazione dei Dati Personali che hai
        fornito.
        <br />
        Se desideri cancellare i Dati Personali in nostro possesso, contattaci utilizzando le informazioni di contatto
        fornite.
        <br />
        Nota che potremmo dover verificare la tua identità prima di rispondere a tali richieste.
        <br />
        Comprendi che sotto alcune circostanze potremmo essere tenuti a conservare alcuni dati per conformità legale o
        per altri motivi legittimi.
      </p>
      <h3 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Diritto di restrizione del trattamento
      </h3>
      <p className={pStyle}>
        Hai il diritto di limitare il nostro trattamento dei tuoi Dati Personali.
        <br />
        In tal caso, non tratteremo i tuoi Dati Personali per alcun altro scopo che non sia quello di conservarli.
      </p>
      <h3 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Diritto alla portabilità dei dati
      </h3>
      <p className={pStyle}>
        Hai il diritto di ricevere una copia delle tue informazioni che abbiamo in un formato strutturato, di uso comune
        e leggibile da dispositivo.
        <br />
        Se desideri una copia delle tue informazioni, contattaci utilizzando le informazioni di contatto fornite.
      </p>
      <h3 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Sicurezza dei tuoi dati personali
      </h3>
      <p className={pStyle}>
        La sicurezza dei tuoi dati personali è importante per noi, ma ricorda che nessun metodo di trasmissione su
        Internet o metodo di archiviazione elettronica è sicuro al 100%. Sebbene ci sforziamo di utilizzare mezzi
        commercialmente accettabili per proteggere i tuoi Dati Personali, non possiamo garantire la loro sicurezza
        assoluta.
      </p>
      <h2 className="heading-4 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Link ad altri siti web
      </h2>
      <p className={pStyle}>
        Il nostro Servizio può contenere collegamenti ad altri siti web che non sono gestiti da noi. Se fai clic su un
        link di terze parti, verrai reindirizzato al sito di quella terza parte. Ti consigliamo vivamente di rivedere
        l'Informativa sulla privacy di ogni sito che visiti.
        <br />
        Non abbiamo alcun controllo e non ci assumiamo responsabilità per il contenuto, le politiche sulla privacy o le
        pratiche di siti o servizi di terze parti.
      </p>
      <h2 className="heading-4 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">
        Modifiche a questa Informativa sulla privacy
      </h2>
      <p className={pStyle}>
        Possono essere apportate modifiche a questa Informativa sulla privacy di tanto in tanto. Ti informeremo di
        eventuali modifiche pubblicando la nuova Informativa sulla privacy in questa pagina.
        <br />
        Ti consigliamo vivamente di rivedere questa Informativa sulla privacy periodicamente per eventuali modifiche. Le
        modifiche a questa Informativa sulla privacy sono effettive quando vengono pubblicate su questa pagina.
      </p>
      <h2 className="heading-4 font-serif text-[#343434] font-bold uppercase tracking-[-1px] pt-4">Contattaci</h2>
      <p className={pStyle + "pb-4"}>
        Se hai domande sulla presente Informativa sulla privacy, contattaci tramite email:
        <a className="text-[#3369b1]"> info@magicvision.ai</a>
      </p>
    </Container>
  )
}

export default PrivacyPolicy
