import React, { lazy, useState } from "react"

// Libraries
import { Col, Container, Navbar, Row, Dropdown } from "react-bootstrap"
import { Link as ScrollTo } from "react-scroll"
import { Parallax } from "react-scroll-parallax"
import { AnimatePresence, m } from "framer-motion"
import { fadeIn } from "../Functions/GlobalAnimations"
import Services from "../Components/Services/Services"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import * as Yup from "yup"
import { Form, Formik } from "formik"

import { resetForm, sendEmail } from "../Functions/Utilities"
import { Input } from "../Components/Form/Form"

import { Header, HeaderNav } from "../Components/Header/Header"
import Buttons from "../Components/Button/Buttons"

import Lists from "../Components/Lists/Lists"
import BlogClassic from "../Components/Blogs/BlogClassic"
import Clients from "../Components/Clients/Clients"
import FooterStyle04 from "../Components/Footers/FooterStyle04"

import SIxS from "../Assets/img/SIxS.png"
import Consolida from "../Assets/img/Consolida.png"
import Politecnico from "../Assets/img/Politecnico.png"
import polihub from "../Assets/img/Polihub.png"
import cameraDiCommercio from "../Assets/img/CameraDiCommercio.jpg"
import Fall from "../Assets/img/Fall-detection.jpg"
import Access from "../Assets/img/Access-monitor.jpg"
import Forest from "../Assets/img/Forest.jpg"
import S2P from "../Assets/img/S2P1.jpg"
import Mauri from "../Assets/img/maurizio.webp"
import Lanzani from "../Assets/img/lanza.webp"
import Mandre from "../Assets/img/mandre.webp"
import Labadini from "../Assets/img/labadini.webp"
import Figini from "../Assets/img/figini.webp"
import Iaia from "../Assets/img/iaia.webp"
import Bg from "../Assets/img/Bg.jpg"
import Graph from "../Assets/img/Graph.jpg"
import GraphIta from "../Assets/img/Graph-ita.jpg"
import Notification from "../Assets/img/App-notification.jpg"
import Event from "../Assets/img/App-event.jpg"
import Video from "../Assets/img/App-video.jpg"

const MessageBox = lazy(() => import("../Components/MessageBox/MessageBox"))

const serviceData = [
  {
    img: Fall,
    icon: "fas fa-arrow-right",
    title: "fall_detection",
    content: "fall_detection_description",
    link: "",
  },
  {
    img: Access,
    icon: "fas fa-arrow-right",
    title: "access_monitoring",
    content: "access_monitoring_description",
    link: "",
  },
]

const listData = [
  {
    icon: "fas fa-check",
    title: "edge_technology_subtitle1",
    content: "edge_technology_description1",
  },
  {
    icon: "fas fa-check",
    title: "edge_technology_subtitle2",
    content: "edge_technology_description2",
  },
  {
    icon: "fas fa-check",
    title: "edge_technology_subtitle3",
    content: "edge_technology_description3",
  },
]

export const blogClassicData = [
  {
    id: 1,
    category: ["Web design", "Concept"],
    blogType: "grid",
    tags: ["Development", "Events", "Media", "Mountains"],
    img: Notification,
    title: "notifications",
    content: "notifications_description",
    author: 1,
    likes: 28,
    comments: 0,
    date: "18 February 2020",
  },
  {
    id: 1,
    category: ["Web design", "Concept"],
    blogType: "grid",
    tags: ["Development", "Events", "Media", "Mountains"],
    img: Event,
    title: "events",
    content: "events_description",
    author: 1,
    likes: 28,
    comments: 0,
    date: "18 February 2020",
  },
  {
    id: 1,
    category: ["Web design", "Concept"],
    blogType: "grid",
    tags: ["Development", "Events", "Media", "Mountains"],
    img: Video,
    title: "videos",
    content: "videos_description",
    author: 1,
    likes: 28,
    comments: 0,
    date: "18 February 2020",
  },
]

const ClientData01 = [
  {
    img: SIxS,
    target: "_blank",
    link: "https://sixs.it/",
  },
  {
    img: Consolida,
    target: "_blank",
    link: "https://consorzioconsolida.it/",
  },
  {
    img: Politecnico,
    target: "_blank",
    link: "https://www.polimi.it",
  },
  {
    img: polihub,
    target: "_blank",
    link: "https://polihub.it",
  },
  {
    img: cameraDiCommercio,
    target: "_blank",
    link: "https://www.milomb.camcom.it/home",
  },
]

const serviceData4 = [
  {
    img: Mauri,
    title: "Lorenzo Mauri",
    content: "mauri_role",
    color: "#828282",
    link: "https://www.linkedin.com/in/lorenzo-mauri-a48670143/",
    icon: "fab fa-linkedin",
  },
  {
    img: Lanzani,
    title: "Federico Lanzani",
    content: "lanzani_role",
    color: "#828282",
    link: "https://www.linkedin.com/in/lanzani/",
    icon: "fab fa-linkedin",
  },
  {
    img: Labadini,
    title: "Matteo Labadini",
    content: "labadini_role",
    color: "#828282",
    link: "https://www.linkedin.com/in/matteo-labadini-627b651b7/",
    icon: "fab fa-linkedin",
  },
  {
    img: Mandre,
    title: "Andrea Mandressi",
    content: "mandressi_role",
    color: "#828282",
    link: "https://www.linkedin.com/in/andrea-mandressi-288a94197/",
    icon: "fab fa-linkedin",
  },
  {
    img: Figini,
    title: "Davide Figini",
    content: "figini_role",
    color: "#828282",
    link: "https://www.linkedin.com/in/davide-figni-0a971521b/",
    icon: "fab fa-linkedin",
  },
  {
    img: Iaia,
    title: "Francesco Iaia",
    content: "iaia_role",
    color: "#828282",
    link: "https://www.linkedin.com/in/francesco-iaia-b4460a17a/",
    icon: "fab fa-linkedin",
  },
]

function Landing() {
  const { t } = useTranslation()
  const [lan, setLang] = useState(i18next.language.split("-")[0])
  console.log(lan)

  return (
    <div>
      {/* Parallax Section Start */}
      {/* <div className="overflow-hidden relative">
      <Parallax
          className="lg-no-parallax bg-cover absolute top-[0px] left-0 w-full h-[100vh] md:h-[90vh] sm:h-[500px]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(${Bg})`,
          }}
        ></Parallax>
        <Container className="relative ">
          <Row className="items-center h-[100vh] md:h-[650px] sm:h-[450px]">
           
            <Col lg={10} md={11} xl={9} className="flex flex-col justify-center full-screen text-center px-10 xs:px-[15px]">
              <div className="bg-[#f7f7f7e5] rounded-[6px] p-[6.5rem] lg:p-5rem] md:p-[4.5rem] sm:px-8">
                <p className="font-serif font-medium uppercase  tracking-[3px] text-[#3369b1] mb-[40px] sm:w-full sm:mb-[25px]">Elevating Senior Living with Intelligent Care</p>
                <h1 className="font-serif font-semibold -tracking-[3px] text-[#343434] mb-14 sm:mb-[20px] xs:-tracking-[.5px] xs:text-[28px] xs:leading-[38px]">Magic Vision</h1>
                <div className="flex flex-row justify-center border-y border-[#ffffff1a] text-center py-[15px] mb-16 xs:block">
                  <div className="mr-[25px] xs:mr-0 sm:mr-[15px]">
                    <span className="text-md text-[#343434]font-serif font-medium uppercase inline-block">Experience independence with safety thanks to Silver Eye, where our advanced AI constant vigilance your loved ones, ensuring a new era of smart senior living.</span>
                  </div>
                </div>
                <ScrollTo to="about" offset={0} delay={0} spy={true} smooth={true} duration={800}>
                <Buttons
                  type="submit"
                  className="btn-fill tracking-[1px] font-medium font-serif uppercase md:mb-[15px] btn-fancy"
                  themeColor="#3369b1"
                  color="#ffffff"
                  size="lg"
                  title="Discover more"
                />
                </ScrollTo>
              </div>
            </Col>
          </Row>
          <ScrollTo to="about" offset={0} delay={0} spy={true} smooth={true} duration={800} className="w-full absolute bottom-[50px] left-0 right-0 z-[1] flex justify-center items-center xs:hidden cursor-pointer">
          </ScrollTo>
        </Container>
      </div> */}
      {/* Parallax Section End */}

      {/* Parallax Section Start */}
      <div className="overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover absolute top-[0px] left-0 w-full h-[100vh] md:h-[90vh] sm:h-[500px]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(${Bg})`,
          }}
        ></Parallax>
        <Container className="relative">
          <Row className="items-center h-[100vh] md:h-[650px] sm:h-[550px]">
            <Col lg={6} md={5} sm={8}>
              <span className="text-xmd font-serif font-medium uppercase text-[#FAFAFA] block mb-[35px] xs:mb-[15px]">
                <span
                  className="left-0 bottom-[2px] top-[12px] w-full h:[5px]"
                  style={{ backgroundImage: "linear-gradient(0,#3369b1 30%,transparent 50%)" }}
                >
                  {t("tagline")}
                </span>
              </span>
              <h1 className="font-serif font-bold text-[#FAFAFA] uppercase mb-[2.5rem] tracking-[-2px] sm:mb-[20px] xs:mb-[15px] xs:text-[4rem]">
                Magic Vision
              </h1>
              <p className="text-xmd font-serif text-[#FAFAFA] leading-[30px] w-[73%] mb-12 lg:w-[90%] md:w-full xs:mb-[15px]">
                {t("atf_description")}
              </p>
              <ScrollTo
                to="about"
                offset={0}
                delay={0}
                spy={true}
                smooth={true}
                duration={800}
                className="cursor-pointer"
              >
                <Buttons
                  type="submit"
                  className="btn-fill tracking-[1px] font-medium font-serif uppercase md:mb-[15px] btn-fancy"
                  themeColor="#3369b1"
                  color="#ffffff"
                  size="lg"
                  title={t("btn_discover_more")}
                />
              </ScrollTo>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Parallax Section End */}

      {/* Section Start*/}
      <m.section
        {...{ ...fadeIn, transition: { delay: 0.3 } }}
        id="about"
        className="py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
      >
        <Container className="product">
          <Row className="items-center md:justify-center">
            <Col lg={4} md={8} className="md:text-center md:mb-24">
              <span className="font-serif text-md text-[#3369b1] uppercase font-medium mb-[25px] inline-block sm:mb-[10px]">
                {t("about_company")}
              </span>
              <h2 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px] w-[80%] mb-[20px] lg:w-[90%] md:w-full">
                {t("our_product")}
              </h2>
              <p className="font-serif w-[80%] mb-[35px] lg:w-full">{t("our_product_description")} </p>
            </Col>
            <Col lg={8} md={12}>
              <Services
                grid="row-cols-1 row-cols-md-2 justify-center text-left gap-y-10 md:gap-y-[45px]"
                theme="service-style-01"
                data={serviceData}
              />
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <section className=" bg-lightgray py-[130px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]">
        <Container className="application">
          <Row className="justify-center">
            <div className="col-12 col-md-7 text-center mb-16 sm:mb-8">
              <h2 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px]">
                {t("application")}
              </h2>
            </div>
          </Row>
          <BlogClassic
            pagination={false}
            grid="grid grid-3col xl-grid-3col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-double-extra-large"
            data={blogClassicData}
            overlayColor="#3369b1"
          />
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <Container>
          <Row className="items-center justify-center">
            <div className="col col-xl-4 col-lg-5 col-md-10 md:mb-20">
              <h2 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px]">
                {t("social_impact_title")}
              </h2>
              <blockquote className="border-l-[4px] font-serif text-darkgray font-medium border-[#3369b1] text-xmd pl-[25px] pr-0 mt-[40px] mb-[30px] lg:w-[95%]">
                {t("social_impact_subtitle")}
              </blockquote>
              <p className=" font-serif w-[90%] mb-[25px] md:w-full">{t("social_impact_description")}</p>
            </div>
            <div className="col-12 col-md-10 col-lg-7 offset-xl-1">
              {lan === "en" ? (
                <img height="" width="" alt="..." src={Graph} />
              ) : (
                <img height="" width="" alt="..." src={GraphIta} />
              )}
            </div>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section
        className="bg-no-repeat bg-cover overflow-hidden relative bg-center border-mediumgray py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] xs:py-0 xs:border-y"
        style={{
          backgroundImage: `url(${Forest})`,
        }}
      >
        <Container className="xs:px-0">
          <Row className="justify-end md:justify-center xs:gx-0">
            <div className="col col-xl-6 col-lg-7 col-md-9 col-sm-11">
              <div className="w-full bg-white py-[6rem] px-[7rem] xs:px-[4rem] xs:py-[6rem] box-shadow-large">
                <h2 className="font-serif heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px] mb-[45px]">
                  {t("edge_technology_title")}
                </h2>
                <Lists theme="list-style-07" data={listData} />
              </div>
            </div>
          </Row>
        </Container>
      </section>
      {/* Section End*/}

      {/* Section Start */}
      <section className="bg-lightgray py-[130px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]">
        <Container className="team">
          <Row className="justify-center">
            <div className="col col-md-7 text-center mb-20 sm:mb-12">
              <span className="font-serif text-md text-[#3369b1] uppercase font-medium inline-block mb-[15px] sm:mb-[10px]">
                {t("magic_vision's")}
              </span>
              <h2 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px]">
                {t("header_team")}
              </h2>
            </div>
          </Row>
          <Services
            grid="row-cols-lg-3 row-cols-sm-2 row-cols-1 gap-y-24"
            theme="service-style-04 items-center"
            data={serviceData4}
          />
          <Row className="justify-center">
            <div className="col col-md-8 text-center mt-20 sm:mb-12">
              <span className="font-serif text-md text-[#3369b1] uppercase font-medium inline-block mb-[15px] sm:mb-[2px]">
                {t("edition")}
              </span>
              <h2 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px]">{t("S2P")}</h2>
            </div>
          </Row>
          <Row className="items-center justify-center">
            <div className="col-12 col-md-10 col-lg-7 ">
              <img height="607" width="1080" alt="..." src={S2P} className="mt-10 sm:mt-0" />
            </div>
            <div className="col col-xl-4 col-lg-5 col-md-10 md:mb-20">
              <blockquote className="border-l-[4px] font-serif text-darkgray font-medium border-[#3369b1] text-xmd pl-[25px] pr-0 mt-[40px] mb-[30px] lg:w-[95%]">
                {t("S2P_description")}
              </blockquote>
              <a
                href="https://www.ilsole24ore.com/art/dalla-sanita-digitale-all-economia-circolare-ecco-vincitori-switch2product-AF07eS0B"
                className="text-darkgray text-xmd underline"
              >
                {t("S2P_link")}
              </a>
            </div>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] cover-background overflow-visible">
        <Container>
          <Row className="justify-center">
            <Col md={10} lg={7} className="text-center">
              <span className="font-serif font-medium text-basecolor text-xmd mb-[20px] inline-block sm:mb-[10px] text-[#3369b1]">
                {t("mailForm_subtitle")}
              </span>
              <h2 className="heading-4 font-serif font-semibold text-darkgray inline-block text-center mb-[65px] xs:mb-[30px]">
                {t("mailForm_title")}
              </h2>
              <p className="font-serif w-[100%] mb-[35px] lg:w-full">{t("mailForm_description")} </p>
              <Formik
                initialValues={{ email: "" }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email("Invalid email.").required("Field is required."),
                })}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true)
                  const response = await sendEmail(values, lan, actions)
                }}
              >
                {({ isSubmitting, status }) => (
                  <div className="relative subscribe-style-04 w-[93%] mx-auto xs:w-full">
                    <Form className="relative" ac>
                      <Input
                        showErrorMsg={false}
                        type="email"
                        name="email"
                        className="border-[1px] border-solid border-transparent large-input xs:mb-[60px] pr-[190px]"
                        placeholder="Enter your email address"
                      />
                      <button
                        aria-label="submit"
                        type="submit"
                        className={`text-xs tracking-[1px] py-[12px] px-[28px] xs:!block uppercase${
                          isSubmitting ? " loading" : ""
                        }`}
                      >
                        {t("mailForm_button")}
                      </button>
                    </Form>
                    <AnimatePresence>
                      {status && (
                        <m.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="font-serif absolute top-[115%] left-0 w-full"
                        >
                          <MessageBox
                            className="py-[5px] rounded-[4px] z-[2]"
                            theme="message-box01"
                            variant="success"
                            message={t("mailForm_success")}
                          />
                        </m.div>
                      )}
                    </AnimatePresence>
                  </div>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[130px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]">
        <Container>
          <Row className="justify-center">
            <div className="col col-md-7 text-center mb-5 sm:mb-12">
              <h2 className="heading-5 font-serif text-[#343434] font-bold uppercase tracking-[-1px]">
                {t("partners")}
              </h2>
            </div>
          </Row>
          <Clients
            grid="mt-10 row-cols-2 row-cols-lg-3 row-cols-md-3 row-cols-sm-2 gap-y-10 justify-center"
            theme="client-logo-style-01"
            data={ClientData01}
          />
        </Container>
      </section>
      {/* Section End */}
    </div>
  )
}

export default Landing
